.store_body {
    background-color: #f5efef;
}

.store_banner_section {
    padding: 20px 10%;
}

.store_main_slider {
    width: 100%;
}

.store_main_slider_slide img {
    width: 100%;
    border-radius: 15px;
}

.store_container {
    width: 100%;
    padding: 30px 10%;
    display: grid;
    grid-template-columns: 300px auto;
}

@media (max-width: 1520px) {
    .store_container {
        width: 100%;
        padding: 30px 5%;
        display: grid;
        grid-template-columns: 300px auto;
    }
}

@media (max-width: 1330px) {
    .store_container {
        width: 100%;
        padding: 30px 2%;
        display: grid;
        grid-template-columns: 300px auto;
    }
}

@media (max-width: 996px) { 
    .store_container {
        padding: 30px 5%;
        grid-template-columns: 1fr;
    }

    .store_banner_section {
        padding: 20px 5%;
    }
}

.product_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 37px;
}

.product_container > div {
    margin-right: 16px;
}

@media (max-width: 996px) { 
    .product_container {
        justify-content: center;  
    }

    .product_container > div {
        margin-right: 0;
    }
}

.filters_container {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
}

.filters_container > h2 {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.filters_container > h2 button {
    display: none;
}

.filter_box {
    width: 100%;
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1), 0 -1px 2px 0 rgba(0,0,0,.1);
    margin-bottom: 20px;
}

.filter_box h2 {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.price_filter .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.price_filter .input input {
    width: 100%;
    margin-top: 2px;
    border-radius: 5px;
    border: 1px solid #949494;
    outline: none;
    padding: 4px 5px;
}

.price_filter .input select {
    width: 100%;
    margin-top: 2px;
    border-radius: 5px;
    border: 1px solid #949494;
    outline: none;
    padding: 4px 5px;
}

@media (max-width: 996px) {
    .filters_container {
        padding-left: 0;
        max-height: 40px;
        overflow: hidden;
        transition: .25s;
    }

    .filters_container.active {
        max-height: 2000px;
    }
    
    .filters_container > h2 {
        font-size: 18px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .filters_container > h2 button {
        display: flex;
    }
    
    .filter_box {
        width: 100%;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.1), 0 -1px 2px 0 rgba(0,0,0,.1);
        margin-bottom: 20px;
    }
    
    .filter_box h2 {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    
    .price_filter .input {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    
    .price_filter .input input {
        width: 100%;
        margin-top: 2px;
        border-radius: 5px;
        border: 1px solid #949494;
        outline: none;
        padding: 4px 5px;
    }
    
    .price_filter .input select {
        width: 100%;
        margin-top: 2px;
        border-radius: 5px;
        border: 1px solid #949494;
        outline: none;
        padding: 4px 5px;
    }
}