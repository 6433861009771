.product_card {
    min-width: 284px;
    max-width: 284px;
    margin-bottom: 16px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1), 0 -1px 2px 0 rgba(0,0,0,.1);
    overflow: hidden;
    cursor: pointer;
    transition: .2s;
}

.product_card:hover {
    transform: translateY(-3px);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.3), 0 -1px 2px 0 rgba(0,0,0,.3);
}

.product_card .media {
    width: 284px;
    height: 284px;
    bottom: 1px solid #e7e7e7;
}

.product_card .media .slider {
    width: 284px;
    height: 284px;
}

.product_card .media .slide {
    position: relative;
}

.product_card .media .slide img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 996px) {
    .product_card {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 25px;
    }

    .product_card .media {
        width: 90vw;
        height: 90vw;
        bottom: 1px solid #e7e7e7;
    }

    .product_card .media .slider {
        width: 90vw;
        height: 90vw;
    }
    
    .product_card .media .slide {
        position: relative;
    }
}

.product_card .content {
    padding: 20px;
}

.product_card .content h2 {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: 10px;
}

.product_card .content .price_before {
    position: relative;
    font-size: 14px;
    font-weight: 300;
    color: #adadad;
}

.product_card .content .price_before::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: rgba(0,0,0,.6);
}

.product_card .content .price {
    display: flex;
    align-items: center;
}

.product_card .content .price span {
    font-size: 24px;
    font-weight: 400;
    color: #181818;
}

.product_card .content .price p {
    margin-left: 10px;
    color: #28a745;
}